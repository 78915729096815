import * as yup from 'yup';
import { ICreateTrackingNoteClassesRequest } from '@/services/trackingNote/types';

const trackingNoteClassFormValidations: yup.ObjectSchema<ICreateTrackingNoteClassesRequest> =
  yup.object().shape({
    lessonID: yup.string().required('Ders zorunludur'),
    classID: yup.string().required('Sınıf zorunludur'),
    campusID: yup.string().required('Kampüs zorunludur'),
    departmentID: yup.string().required('Birim zorunludur'),
    gradeID: yup.string().required('Sınıf zorunludur'),
    title: yup
      .string()
      .required('Başlık zorunludur')
      .max(255, 'Başlık 255 karakterden uzun olmamalıdır'),
    description: yup
      .string()
      .required('Açıklama zorunludur')
      .max(10000, 'Açıklama 10000 karakterden uzun olmamalıdır'),
    studentVisibility: yup.boolean().default(false),
    parentVisibility: yup.boolean().default(false),
    managementVisibility: yup.boolean().default(false),
    counselorVisibility: yup.boolean().default(false),
    creatorOnlyVisibility: yup.boolean().default(false),
    attachment: yup.string().optional(),
    students: yup.array().min(1, 'En az bir öğrenci seçilmelidir'),
    sendSmsToParents: yup.boolean().optional(),
  });

export default trackingNoteClassFormValidations;
